<template>
  <div class="container">
    <div class="form-card">
      <div class="card-head">
        <h4 v-if="this.$route.params.id" class="card-title">
          <span>Geliştirici Hesabı Detay</span> 
          <i class="icon icon-arrow-right-3"></i>
          {{ formData.name }}
        </h4>
        <h4 v-else class="card-title">Geliştirici Hesabı Ekle</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="">name</label>
              <input type="text" class="form-control" v-model="formData.name" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">account_user_name</label>
              <input
                type="text"
                class="form-control"
                v-model="formData.account_user_name"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">account_password</label>
              <input
                type="text"
                class="form-control"
                v-model="formData.account_password"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">enrolled_as</label>
              <input
                type="text"
                class="form-control"
                v-model="formData.enrolled_as"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">platform</label>
              <select v-model="platform" class="form-select">
                <option :value="item" v-for="item in platformList" :key="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Firma</label>
              <select v-model="formData.customer" class="form-select">
                <option
                  :value="item.id"
                  v-for="item in customerList"
                  :key="item"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-12 mt-20">
            <button
              class="btn btn-filled-primary"
              @click="updateAccount"
              v-if="this.$route.params.id"
            >
              Güncelle
            </button>
            <button class="btn btn-filled-primary" @click="addAccount" v-else>
              Ekle
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Done
    @closeModal="doneModalClose"
    v-if="isCloseModal"
    :doneMsg="popupText"
    :doneTitle="popupTitle"
    @click="closeModal"
    link="/platform-accounts"
  />
</template>
<script>
import useValidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useValidate(),
      isCloseModal: false,
      popupText: "",
      popupTitle: "",
      platformList: ["apple", "google"],
      platform: "",
      formData: {
        name: "",
        account_user_name: "",
        account_password: "",
        enrolled_as: "",
        customer: "",
      },
      customerList: [],
    };
  },
  validations() {
    return {};
  },
  created() {
    this.getCustomers();
    if (this.$route.params.id) {
      this.$axios
        .get(
          `/developer-account/${this.$route.params.platform}/${this.$route.params.id}/`
        )
        .then((resp) => {
          let item = resp.data;
          this.formData = {
            name: item.name,
            account_user_name: item.account_user_name,
            account_password: item.account_password,
            enrolled_as: item.enrolled_as,
            customer: item.customer,
          };
          this.platform = this.$route.params.platform;
        });
      this.$store.commit("theme/addToBreadcrumps", [
        {
          title: "Anasayfa",
          path: "/",
        },
        {
          title: "Geliştiri Hesapları",
          path:"/platform-accounts"
        },
        {
          title: "Geliştiri Hesap Detay",
        },
        {
          title: `${this.$route.params.id}`,
        },
      ]);
    }
  },

  methods: {
    doneModalClose(value) {
      this.isCloseModal = value;
    },
    addAccount() {
      this.$axios
        .post(`/developer-account/${this.platform}`, this.formData)
        .then(() => {
          this.popupText = "Hesap Eklendi";
          this.popupTitle = "Hesap Ekleme";
          this.isCloseModal = true;
        });
    },
    updateAccount() {
      this.$axios
        .put(
          `/developer-account/${this.$route.params.platform}/${this.$route.params.id}/`,
          this.formData
        )
        .then((resp) => {
          this.popupText = "Hesap Güncellendi";
          this.popupTitle = "Hesap Güncelleme";
          this.isCloseModal = true;
        });
    },
    getCustomers() {
      this.$axios.get("/customer/").then((response) => {
        response.data.filter((customer) => {
          this.customerList.push({
            id: customer.id,
            name: customer.name,
          });
        });
      });
    },
  },
};
</script>